<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  gatherListAPI,
  gatherListSummaryAPI
} from '@/api/shop/report/gatherQuery' //门店收款查询
export default {
  name: 'GatherQuery',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '门店收款查询',
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/收银员编号/收银员名称/门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'cashierNos', label: '收银员编号' },
              { filter: 'cashierNames', label: '收银员名称' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          {
            label: '收款类型',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'payTypeIds',
            option: {
              multiple: true,
              data: [
                { payTypeName: '总部充值', payTypeId: '0' },
                { payTypeName: '门店充值', payTypeId: '1' },
                { payTypeName: '门店销售', payTypeId: '2' },
                { payTypeName: '门店预定', payTypeId: '3' },
                { payTypeName: '线上充值', payTypeId: '4' },
                { payTypeName: '自营商城', payTypeId: '5' },
                { payTypeName: '美团', payTypeId: '6' },
                { payTypeName: '饿了么', payTypeId: '7' },
                { payTypeName: '有赞', payTypeId: '8' }
              ],
              label: 'payTypeName',
              value: 'payTypeId',
              filterable: true
            }
          },
          this.$select({
            key: 'listPayClass',
            option: {
              filter: 'payModeIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择收款方式',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'paymode' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCashier',
            option: {
              filter: 'cashierIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择收银员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'cashier' })
                    }
                  }
                ]
              }
            }
          }),
          {
            label: '是否营收',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'isRevenue',
            option: {
              clearable: true,
              data: [
                { label: '是', value: true },
                { label: '否', value: false }
              ],
              label: 'label',
              value: 'value',
              filterable: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'shopPaymentCashier',
        tabsColumns: [
          {
            title: '门店收款汇总（门店+收款方式+收银员）',
            type: 'shopPaymentCashier',
            getListApi: gatherListAPI,
            getSummaryApi: gatherListSummaryAPI,
            defaultBody: { summaryType: 1 },
            exportOption: {
              show: true,
              fastExportUrl:
                '/api/system/shop/report/sale/pay/shopPayModeListExport',
              exportName: '门店收款汇总（门店+收款方式+收银员）'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'payType',
                label: '收款类型',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payModeName',
                label: '收款方式',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payMoney',
                label: '收款金额',
                type: 'link',
                click: 'update',
                minWidth: 100,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['payMoney']
          },
          {
            title: '门店收款汇总（收款方式+收银员）',
            type: 'paymentCashier',
            getListApi: gatherListAPI,
            getSummaryApi: gatherListSummaryAPI,
            defaultBody: { summaryType: 2 },
            exportOption: {
              show: true,
              fastExportUrl:
                '/api/system/shop/report/sale/pay/shopPayModeListExport',
              exportName: '门店收款汇总（收款方式+收银员）'
            },
            //表格
            columns: [
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'payType',
                label: '收款类型',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payModeName',
                label: '收款方式',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payMoney',
                label: '收款金额',
                type: 'link',
                click: 'update',
                minWidth: 100,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['payMoney']
          },
          {
            title: '门店收款汇总（收款方式）',
            type: 'paymentCashier',
            getListApi: gatherListAPI,
            getSummaryApi: gatherListSummaryAPI,
            defaultBody: { summaryType: 3 },
            exportOption: {
              show: true,
              fastExportUrl:
                '/api/system/shop/report/sale/pay/shopPayModeListExport',
              exportName: '门店收款汇总（收款方式）'
            },
            //表格
            columns: [
              {
                prop: 'payType',
                label: '收款类型',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payModeName',
                label: '收款方式',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payMoney',
                label: '收款金额',
                type: 'link',
                click: 'update',
                minWidth: 100,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['payMoney']
          },
          {
            title: '门店收款汇总（收款方式+日期）',
            type: 'paymentCashier',
            getListApi: gatherListAPI,
            getSummaryApi: gatherListSummaryAPI,
            defaultBody: { summaryType: 4 },
            exportOption: {
              show: true,
              fastExportUrl:
                '/api/system/shop/report/sale/pay/shopPayModeListExport',
              exportName: '门店收款汇总（收款方式+日期）'
            },
            //表格
            columns: [
              {
                prop: 'billDateStr',
                label: '日期',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'payType',
                label: '收款类型',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payModeName',
                label: '收款方式',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'payMoney',
                label: '收款金额',
                type: 'link',
                click: 'update',
                minWidth: 100,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['payMoney']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'update':
          const obj = {
            pageNum: 1,
            pageSize: 15,
            // cashierId: row.cashierId,
            payModeId: row.payModeId,
            // shopId: row.shopId,
            // beginBillDate: this.options?.body?.beginBillDate,
            // endBillDate: this.options?.body?.endBillDate,
            // payTypeId: row.payTypeId,
            isRevenue: this.options?.body?.isRevenue,
            billIds: row?.billIds
          }
          const shopObj = JSON.stringify(obj)
          this.$router.push({
            name: 'gatherQueryDetail',
            query: {
              shopObj: shopObj,
              payType: row.payType == '充值' ? true : false,
              type: 'Update'
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
